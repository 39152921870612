<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in getBreadcrumbs()"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  methods: {
    getBreadcrumbs() {
      this.$route.meta.breadcrumb.forEach(function (item) {
        if (item.to && !item.tooriginal) {
          item.tooriginal = item.to
        }
      })

      let breadcrumbs = Object.assign([], this.$route.meta.breadcrumb)
      let params = this.$route.params;

      breadcrumbs.forEach(function (item) {
        if (item.to) {
          if (item.to && item.tooriginal) item.to = item.tooriginal;
          for (const [key, value] of Object.entries(params)) {
            item.to = item.to.replace(":" + key, value)
          }
        }
      });

      return breadcrumbs
    },
  },
};
</script>
